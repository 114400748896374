import React from 'react'

import GeneralPageLayout from '../components/Layouts/GeneralPage'
import MainTextBlock from '../components/UI/MainTextBlock/MainTextBlock'
import SectionContainer from './../components/UI/Containers/SectionContainer'
import SectionHeader from './../components/UI/SectionHeader/SectionHeader'
import CapabilityGraphic from '../components/UI/Capabilities/CapabilityGraphic/CapabilityGraphic'
import ExpertCapabilities from '../components/UI/Capabilities/ExpertCapabilities/ExpertCapabilities'
import ServiceIcon from './../images/icons/capabilities/ElectronicWarfare.inline.svg'
import ElectronicWarfareGraphic from './../images/capabilities/Electronic-Warfare.png'

import Radar from '../components/UI/Images/Radar/Radar'

const ElectronicWarfarePage = () => {
  const pageInfo = {
    pageType: 'interior',
    title1: 'Electronic',
    title2: 'Warfare',
    breadcrumbs: ['Capabilities'],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <div className="content">
        {/* Capabilities Video */}
        <SectionContainer
          id="serviceVideo"
          color="white"
          type="pressedEdgeRight"
          classes={['inlineChildren_half', 'capabilitySection']}
        >
          <section>
            <SectionHeader color="navy" alignment="left" size="med">
              Delivering <span>Proven Technologies</span>
            </SectionHeader>
            <MainTextBlock>
              <p>
                Our electronic warfare (EW) professionals support extensive
                capabilities across Navy ground, sea, and air weapon systems. We
                support systems from testing & analysis to operation &
                sustainment. Our aim is to deliver proven technologies and
                functional capabilities in order to best support our customers.
              </p>
            </MainTextBlock>
          </section>
          <CapabilityGraphic
            src={ElectronicWarfareGraphic}
            title="Electronic Warfare satellites"
          />
        </SectionContainer>

        {/* Radar Animation */}
        <SectionContainer
          id="countriesSupported"
          color="gray"
          type="fullWidth"
          classes={['centerContents']}
        >
          <Radar />
        </SectionContainer>

        {/* Expert Capabilities */}
        <SectionContainer id="" color="white" type="pressedEdgeLeft">
          <ExpertCapabilities
            serviceName="Electronic Warfare"
            ServiceIcon={ServiceIcon}
            hexSize="small"
          />
        </SectionContainer>
      </div>
    </GeneralPageLayout>
  )
}

export default ElectronicWarfarePage

import React, { Fragment } from 'react'
import * as styles from './Radar.module.scss'

const Radar = () => {
  return (
    <Fragment>
      <article className={`${styles.radarWrap}`}>
        <div className={`${styles.radar}`}>
          <div className={`${styles.pointer}`}></div>
          <div className={`${styles.point}`}></div>
          <div className={`${styles.point}`}></div>
          <div className={`${styles.point}`}></div>
          <div className={`${styles.point}`}></div>
          <div className={`${styles.point}`}></div>
          <div className={`${styles.point}`}></div>
        </div>
        <section className={`${styles.radarList}`}>
          <ul>
            <li>Depot, acquisitions, technical data, &amp; AIT support</li>
            <li>Test equipment repair</li>
            <li>System design documentation</li>
            <li>Systems engineering</li>
            <li>Text &amp; evaluation</li>
            <li>RM&amp;A, DMSMS, &amp; PH&amp;T</li>
            <li>Sustainment &amp; Performance based logistics</li>
            <li>Pod platform integration</li>
            <li>Risk management</li>
          </ul>
        </section>
      </article>
    </Fragment>
  )
}

export default Radar
